import { render, staticRenderFns } from "./ticketLogDetails.vue?vue&type=template&id=56182058&scoped=true&"
import script from "./ticketLogDetails.vue?vue&type=script&lang=js&"
export * from "./ticketLogDetails.vue?vue&type=script&lang=js&"
import style0 from "./ticketLogDetails.vue?vue&type=style&index=0&id=56182058&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "56182058",
  null
  
)

export default component.exports