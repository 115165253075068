import request from '../utils/request'
import baseUrl from './baseUrl'

export const ticketLogList = (data) => request({
  url: baseUrl + '/ticketLog/selectForBack',
  method: 'POST',
  data
})
export const ticketLogDetails = (data) => request({
  url: baseUrl + '/ticketLog/selectForId',
  method: 'POST',
  data
})